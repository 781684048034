<template>

  <div>
    <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="4"
          class="w-100"
        >
          <v-select
            v-model="filterDataStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterStatusOptions"
            :clearable="false"
            :placeholder="'Select Status'"
          />
        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <div class="d-flex align-items-center">
            <b-button
              class="ml-1"
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refPaymentContractsListTable"
        :items="fetchPaymentContractsList"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: user_name -->
        <template #cell(user_name)="data">
          <b-link
            :to="{
              name: 'admin-application-edit',
              params: {
                id: data.item.application.id,
                type: getApplicationUserType(data.item.user),
              },
              query: { activeTab: 'payment-contract' },
            }"
          >{{ data.item.user.full_name }}</b-link>
        </template>

        <!-- Column: pay_condition_start_date -->
        <template #cell(pay_condition_start_date)="data">
          {{ data.item.start_date | shortDateLocal }}
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(currentStatus(data.item))}`"
          >
            {{ currentStatus(data.item) }}
          </b-badge>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BLink, BBadge, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import { filters } from '@core/mixins/filters'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/program-users-contracts/userPaymentContractsStoreModule'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import useProgramUsersContractsList from '@/views/admin/program-users-contracts/useProgramUsersContractsList'

export default {
  name: 'ProgramUsersContractsList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BLink,
    BBadge,
    BButton,

    vSelect,

    TableSpinner,
  },
  mixins: [filters],
  data() {
    return {
      filterStatusOptions: [
        'Unselected',
        'Selected',
        'Approved',
      ],
      isLoading: false,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-user-contracts'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchPaymentContractsList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      filterDataStatus,
      sortBy,
      totalItems,
      isSortDirDesc,
      refPaymentContractsListTable,
      actionOptions,
      clearFilters,
      refetchData,
      resolveStatusVariant,
    } = useProgramUsersContractsList()

    return {
      fetchPaymentContractsList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      filterDataStatus,
      sortBy,
      totalItems,
      isSortDirDesc,
      refPaymentContractsListTable,
      actionOptions,
      clearFilters,
      refetchData,
      resolveStatusVariant,
    }
  },
  methods: {
    getApplicationUserType(user) {
      return user.role.display_name === 'Staff' ? user.role.display_name : this.camperStudent(1)
    },
    currentStatus(item) {
      if (item.selected_option && item.application.is_accepted) {
        return 'Approved'
      }
      if (item.selected_option) {
        return 'Selected'
      }
      return 'Unselected'
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header {

  label {
    margin-bottom: 0;
  }
}

.badge {
  text-transform: uppercase;
}

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
